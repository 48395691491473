import Welcome from './home';
import sideNavigationMenu from './layout/sideNavigationMenu';
import Navigation from './layout/navigation';
import Footer from './layout/footer';
import React, { Component }  from 'react';

const Application = () => {
    return (
        <>
            <Welcome  />
        </>
    )
}

export default Application