import React, { Component }  from 'react';

class sideNavigationMenu extends Component {
    render() {
        return (
            <>
                <aside id="sidebarHeader1" className="u-sidebar u-sidebar--left" aria-labelledby="sidebarHeaderInvoker">
                    <div className="u-sidebar__scroller">
                        <div className="u-sidebar__container">
                            <div className="u-header-sidebar__footer-offset">
                                {/* Toggle Button */}
                                <div className="position-absolute top-0 right-0 z-index-2 pt-4 pr-4 bg-white">
                                    <button type="button" className="close ml-auto"
                                        aria-controls="sidebarHeader"
                                        aria-expanded="false"
                                        data-unfold-event="click"
                                        data-unfold-hide-on-scroll="false"
                                        data-unfold-target="#sidebarHeader1"
                                        data-unfold-type="css-animation"
                                        data-unfold-animation-in="fadeInLeft"
                                        data-unfold-animation-out="fadeOutLeft"
                                        data-unfold-duration="500">
                                        <span aria-hidden="true"><i className="ec ec-close-remove text-gray-90 font-size-20"></i></span>
                                    </button>
                                </div>
                                {/* End Toggle Button */}

                                {/* Content */}
                                <div className="js-scrollbar u-sidebar__body">
                                    <div id="headerSidebarContent" className="u-sidebar__content u-header-sidebar__content">
                                        {/* Logo */}
                                        <a className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center mb-3" href="index.html" aria-label="Electro">
                                            <svg version="1.1" x="0px" y="0px" width="175.748px" height="42.52px" viewBox="0 0 175.748 42.52" enableBackground="new 0 0 175.748 42.52" style={{ marginBottom: 0 }}>
                                                <ellipse className="ellipse-bg" fillRule="evenodd" clipRule="evenodd" fill="#FDD700" cx="170.05" cy="36.341" rx="5.32" ry="5.367"></ellipse>
                                                <path fillRule="evenodd" clipRule="evenodd" fill="#333E48" d="M30.514,0.71c-0.034,0.003-0.066,0.008-0.056,0.056
                                                                        C30.263,0.995,29.876,1.181,29.79,1.5c-0.148,0.548,0,1.568,0,2.427v36.459c0.265,0.221,0.506,0.465,0.725,0.734h6.187
                                                                        c0.2-0.25,0.423-0.477,0.669-0.678V1.387C37.124,1.185,36.9,0.959,36.701,0.71H30.514z M117.517,12.731
                                                                        c-0.232-0.189-0.439-0.64-0.781-0.734c-0.754-0.209-2.039,0-3.121,0h-3.176V4.435c-0.232-0.189-0.439-0.639-0.781-0.733
                                                                        c-0.719-0.2-1.969,0-3.01,0h-3.01c-0.238,0.273-0.625,0.431-0.725,0.847c-0.203,0.852,0,2.399,0,3.725
                                                                        c0,1.393,0.045,2.748-0.055,3.725h-6.41c-0.184,0.237-0.629,0.434-0.725,0.791c-0.178,0.654,0,1.813,0,2.765v2.766
                                                                        c0.232,0.188,0.439,0.64,0.779,0.733c0.777,0.216,2.109,0,3.234,0c1.154,0,2.291-0.045,3.176,0.057v21.277
                                                                        c0.232,0.189,0.439,0.639,0.781,0.734c0.719,0.199,1.969,0,3.01,0h3.01c1.008-0.451,0.725-1.889,0.725-3.443
                                                                        c-0.002-6.164-0.047-12.867,0.055-18.625h6.299c0.182-0.236,0.627-0.434,0.725-0.79c0.176-0.653,0-1.813,0-2.765V12.731z
                                                                        M135.851,18.262c0.201-0.746,0-2.029,0-3.104v-3.104c-0.287-0.245-0.434-0.637-0.781-0.733c-0.824-0.229-1.992-0.044-2.898,0
                                                                        c-2.158,0.104-4.506,0.675-5.74,1.411c-0.146-0.362-0.451-0.853-0.893-0.96c-0.693-0.169-1.859,0-2.842,0h-2.842
                                                                        c-0.258,0.319-0.625,0.42-0.725,0.79c-0.223,0.82,0,2.338,0,3.443c0,8.109-0.002,16.635,0,24.381
                                                                        c0.232,0.189,0.439,0.639,0.779,0.734c0.707,0.195,1.93,0,2.955,0h3.01c0.918-0.463,0.725-1.352,0.725-2.822V36.21
                                                                        c-0.002-3.902-0.242-9.117,0-12.473c0.297-4.142,3.836-4.877,8.527-4.686C135.312,18.816,135.757,18.606,135.851,18.262z
                                                                        M14.796,11.376c-5.472,0.262-9.443,3.178-11.76,7.056c-2.435,4.075-2.789,10.62-0.501,15.126c2.043,4.023,5.91,7.115,10.701,7.9
                                                                        c6.051,0.992,10.992-1.219,14.324-3.838c-0.687-1.1-1.419-2.664-2.118-3.951c-0.398-0.734-0.652-1.486-1.616-1.467
                                                                        c-1.942,0.787-4.272,2.262-7.134,2.145c-3.791-0.154-6.659-1.842-7.524-4.91h19.452c0.146-2.793,0.22-5.338-0.279-7.563
                                                                        C26.961,15.728,22.503,11.008,14.796,11.376z M9,23.284c0.921-2.508,3.033-4.514,6.298-4.627c3.083-0.107,4.994,1.976,5.685,4.627
                                                                        C17.119,23.38,12.865,23.38,9,23.284z M52.418,11.376c-5.551,0.266-9.395,3.142-11.76,7.056
                                                                        c-2.476,4.097-2.829,10.493-0.557,15.069c1.997,4.021,5.895,7.156,10.646,7.957c6.068,1.023,11-1.227,14.379-3.781
                                                                        c-0.479-0.896-0.875-1.742-1.393-2.709c-0.312-0.582-1.024-2.234-1.561-2.539c-0.912-0.52-1.428,0.135-2.23,0.508
                                                                        c-0.564,0.262-1.223,0.523-1.672,0.676c-4.768,1.621-10.372,0.268-11.537-4.176h19.451c0.668-5.443-0.419-9.953-2.73-13.037
                                                                        C61.197,13.388,57.774,11.12,52.418,11.376z M46.622,23.343c0.708-2.553,3.161-4.578,6.242-4.686
                                                                        c3.08-0.107,5.08,1.953,5.686,4.686H46.622z M160.371,15.497c-2.455-2.453-6.143-4.291-10.869-4.064
                                                                        c-2.268,0.109-4.297,0.65-6.02,1.524c-1.719,0.873-3.092,1.957-4.234,3.217c-2.287,2.519-4.164,6.004-3.902,11.007
                                                                        c0.248,4.736,1.979,7.813,4.627,10.326c2.568,2.439,6.148,4.254,10.867,4.064c4.457-0.18,7.889-2.115,10.199-4.684
                                                                        c2.469-2.746,4.012-5.971,3.959-11.063C164.949,21.134,162.732,17.854,160.371,15.497z M149.558,33.952
                                                                        c-3.246-0.221-5.701-2.615-6.41-5.418c-0.174-0.689-0.26-1.25-0.4-2.166c-0.035-0.234,0.072-0.523-0.045-0.77
                                                                        c0.682-3.698,2.912-6.257,6.799-6.547c2.543-0.189,4.258,0.735,5.52,1.863c1.322,1.182,2.303,2.715,2.451,4.967
                                                                        C157.789,30.669,154.185,34.267,149.558,33.952z M88.812,29.55c-1.232,2.363-2.9,4.307-6.13,4.402
                                                                        c-4.729,0.141-8.038-3.16-8.025-7.563c0.004-1.412,0.324-2.65,0.947-3.726c1.197-2.061,3.507-3.688,6.633-3.612
                                                                        c3.222,0.079,4.966,1.708,6.632,3.668c1.328-1.059,2.529-1.948,3.9-2.99c0.416-0.315,1.076-0.688,1.227-1.072
                                                                        c0.404-1.031-0.365-1.502-0.891-2.088c-2.543-2.835-6.66-5.377-11.704-5.137c-6.02,0.288-10.218,3.697-12.484,7.846
                                                                        c-1.293,2.365-1.951,5.158-1.729,8.408c0.209,3.053,1.191,5.496,2.619,7.508c2.842,4.004,7.385,6.973,13.656,6.377
                                                                        c5.976-0.568,9.574-3.936,11.816-8.354c-0.141-0.271-0.221-0.604-0.336-0.902C92.929,31.364,90.843,30.485,88.812,29.55z">
                                                </path>
                                            </svg>
                                        </a>
                                        {/* End Logo */}

                                        {/* List */}
                                        <ul id="headerSidebarList" className="u-header-collapse__nav">
                                            {/* Value of the Day */}
                                            <li className="">
                                                <a className="u-header-collapse__nav-link font-weight-bold" href="/#">Value of the Day</a>
                                            </li>
                                            {/* End Value of the Day */}

                                            {/* Top 100 Offers */}
                                            <li className="">
                                                <a className="u-header-collapse__nav-link font-weight-bold" href="/#">Top 100 Offers</a>
                                            </li>
                                            {/* End Top 100 Offers */}

                                            {/* New Arrivals */}
                                            <li className="">
                                                <a className="u-header-collapse__nav-link font-weight-bold" href="/#">New Arrivals</a>
                                            </li>
                                            {/* End New Arrivals */}

                                            {/* Computers & Accessories */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarComputersCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarComputersCollapse">
                                                    Computers &amp; Accessories
                                                </a>

                                                <div id="headerSidebarComputersCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Computers &amp; Accessories</span></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">All Computers &amp; Accessories</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Laptops, Desktops &amp; Monitors</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Printers &amp; Ink</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Networking &amp; Internet Devices</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Computer Accessories</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Software</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Office &amp; Stationery</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Office &amp; Stationery</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Electronics</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Computers & Accessories */}

                                            {/* Cameras, Audio & Video */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarCamerasCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarCamerasCollapse">
                                                    Cameras, Audio &amp; Video
                                                </a>

                                                <div id="headerSidebarCamerasCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Cameras &amp; Photography</span></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Lenses</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Camera Accessories</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Security &amp; Surveillance</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Binoculars &amp; Telescopes</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Camcorders</a></li>
                                                        <li className=""><a className="u-header-collapse__submenu-nav-link" href="/#">Software</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Audio &amp; Video</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Audio &amp; Video</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Headphones &amp; Speakers</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Electronics</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Cameras, Audio & Video */}

                                            {/* Mobiles & Tablets */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarMobilesCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarMobilesCollapse">
                                                    Mobiles &amp; Tablets
                                                </a>

                                                <div id="headerSidebarMobilesCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Mobiles &amp; Tablets</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Mobile Phones</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Smartphones</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Refurbished Mobiles</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Cases &amp; Covers</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Mobile Accessories</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Cases &amp; Covers</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Tablets</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Tablet Accessories</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Electronics</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Mobiles & Tablets */}

                                            {/* Movies, Music & Video */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarMoviesCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarMoviesCollapse">
                                                    Movies, Music &amp; Video
                                                </a>

                                                <div id="headerSidebarMoviesCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Movies &amp; TV Shows</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Movies &amp; TV Shows</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All English</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Hindi</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Video Games</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">PC Games</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Consoles</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Accessories</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Music</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Music</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Indian Classical</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Musical Instruments</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Movies, Music & Video */}

                                            {/* TV & Audio */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarTvCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarTvCollapse">
                                                    TV &amp; Audio
                                                </a>

                                                <div id="headerSidebarTvCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Audio &amp; Video</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Audio &amp; Video</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Televisions</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Headphones</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Speakers</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Audio &amp; Video Accessories</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Music</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Televisions</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Headphones</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Electro Home Appliances</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End TV & Audio */}

                                            {/* Watches & Eyewear */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarWatchesCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarWatchesCollapse">
                                                    Watches &amp; Eyewear
                                                </a>

                                                <div id="headerSidebarWatchesCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Watches</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Watches</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Men's Watches</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Women's Watches</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Premium Watches</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Deals on Watches</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Eyewear</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Men's Sunglasses</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Watches & Eyewear */}

                                            {/* Car, Motorbike & Industrial */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarCarCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarCarCollapse">
                                                    Car, Motorbike &amp; Industrial
                                                </a>

                                                <div id="headerSidebarCarCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><span className="u-header-sidebar__sub-menu-title">Car &amp; Motorbike</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Cars &amp; Bikes</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Car &amp; Bike Care</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Lubricants</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Shop for Bike</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Helmets &amp; Gloves</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Bike Parts</a></li>
                                                        <li><span className="u-header-sidebar__sub-menu-title">Industrial Supplies</span></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Industrial Supplies</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Lab &amp; Scientific</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Car, Motorbike & Industrial */}

                                            {/* Accessories */}
                                            <li className="u-has-submenu u-header-collapse__submenu">
                                                <a className="u-header-collapse__nav-link u-header-collapse__nav-pointer" href="/#" data-target="#headerSidebarAccessoriesCollapse" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="headerSidebarAccessoriesCollapse">
                                                    Accessories
                                                </a>

                                                <div id="headerSidebarAccessoriesCollapse" className="collapse" data-parent="#headerSidebarContent">
                                                    <ul className="u-header-collapse__nav-list">
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Cases</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Chargers</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Headphone Accessories</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Headphone Cases</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Headphones</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">All Industrial Supplies</a></li>
                                                        <li><a className="u-header-collapse__submenu-nav-link" href="/#">Lab &amp; Scientific</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/* End Accessories */}
                                        </ul>
                                        {/* End List */}
                                    </div>
                                </div>
                                {/* End Content */}
                            </div>
                            {/* Footer */}
                            <footer id="SVGwaveWithDots" className="svg-preloader u-header-sidebar__footer">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item pr-3">
                                        <a className="u-header-sidebar__footer-link text-gray-90" href="/#">Privacy</a>
                                    </li>
                                    <li className="list-inline-item pr-3">
                                        <a className="u-header-sidebar__footer-link text-gray-90" href="/#">Terms</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="u-header-sidebar__footer-link text-gray-90" href="/#">
                                            <i className="fas fa-info-circle"></i>
                                        </a>
                                    </li>
                                </ul>

                                {/* SVG Background Shape */}
                                <div className="position-absolute right-0 bottom-0 left-0 z-index-n1">
                                    <img className="js-svg-injector" src="../../assets/svg/components/wave-bottom-with-dots.svg" alt=""
                                        data-parent="#SVGwaveWithDots" />
                                </div>
                                {/* End SVG Background Shape */}
                            </footer>
                            {/* End Footer */}
                        </div>
                    </div>
                </aside>
            </>
        )
    }
}

export default sideNavigationMenu