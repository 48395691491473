import React, { Component }  from 'react';
import { DealsSkeleton } from './skeleton/skeletonElements';

class Deals extends Component {
    render() {
        return (
            <>
                <div className="products-group-4-1-4 space-1 bg-gray-7">
                    <h2 className="sr-only">Products Grid</h2>
                    <div className="container">
                        {/* Nav Classic */}
                        <div className="position-relative text-center z-index-2 mb-3">
                            <ul className="nav nav-classic nav-tab nav-tab-sm px-md-3 justify-content-start justify-content-lg-center flex-nowrap flex-lg-wrap overflow-auto overflow-lg-visble border-md-down-bottom-0 pb-1 pb-lg-0 mb-n1 mb-lg-0" id="pills-tab-1" role="tablist">
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link active " id="Tpills-one-example1-tab" data-toggle="pill" href="#Tpills-one-example1" role="tab" aria-controls="Tpills-one-example1" aria-selected="true">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Best Deals
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-two-example1-tab" data-toggle="pill" href="#Tpills-two-example1" role="tab" aria-controls="Tpills-two-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            TV & Video
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-three-example1-tab" data-toggle="pill" href="#Tpills-three-example1" role="tab" aria-controls="Tpills-three-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Cameras
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-four-example1-tab" data-toggle="pill" href="#Tpills-four-example1" role="tab" aria-controls="Tpills-four-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Audio
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-five-example1-tab" data-toggle="pill" href="#Tpills-five-example1" role="tab" aria-controls="Tpills-five-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Smartphones
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-six-example1-tab" data-toggle="pill" href="#Tpills-six-example1" role="tab" aria-controls="Tpills-six-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            GPS & Navi
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-seven-example1-tab" data-toggle="pill" href="#Tpills-seven-example1" role="tab" aria-controls="Tpills-seven-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Computers
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-eight-example1-tab" data-toggle="pill" href="#Tpills-eight-example1" role="tab" aria-controls="Tpills-eight-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Portable Audio
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Tpills-nine-example1-tab" data-toggle="pill" href="#Tpills-nine-example1" role="tab" aria-controls="Tpills-nine-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Accessories
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* End Nav Classic */}

                        {/* Tab Content */}
                        <div className="tab-content" id="Tpills-tabContent">
                            <div className="tab-pane fade pt-2 show active" id="Tpills-one-example1" role="tabpanel" aria-labelledby="Tpills-one-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-two-example1" role="tabpanel" aria-labelledby="Tpills-two-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-three-example1" role="tabpanel" aria-labelledby="Tpills-three-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-four-example1" role="tabpanel" aria-labelledby="Tpills-four-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-five-example1" role="tabpanel" aria-labelledby="Tpills-five-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-six-example1" role="tabpanel" aria-labelledby="Tpills-six-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-seven-example1" role="tabpanel" aria-labelledby="Tpills-seven-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-eight-example1" role="tabpanel" aria-labelledby="Tpills-eight-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Tpills-nine-example1" role="tabpanel" aria-labelledby="Tpills-nine-example1-tab">
                                <DealsSkeleton />
                            </div>
                        </div>
                        {/* End Tab Content */}
                    </div>

                    {/* Features Section */}
                    <div className="container space-2 d-none">
                        {/* Nav Classic */}
                        <div className="position-relative text-center z-index-2 mb-3">
                            <ul className="nav nav-classic nav-tab nav-tab-sm px-md-3 justify-content-start justify-content-lg-center flex-nowrap flex-lg-wrap overflow-auto overflow-lg-visble border-md-down-bottom-0 pb-1 pb-lg-0 mb-n1 mb-lg-0" id="pills-tab-2" role="tablist">
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link active " id="Gpills-one-example1-tab" data-toggle="pill" href="#Gpills-one-example1" role="tab" aria-controls="Gpills-one-example1" aria-selected="true">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Best Deals
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-two-example1-tab" data-toggle="pill" href="#Gpills-two-example1" role="tab" aria-controls="Gpills-two-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            TV & Video
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-three-example1-tab" data-toggle="pill" href="#Gpills-three-example1" role="tab" aria-controls="Gpills-three-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Cameras
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-four-example1-tab" data-toggle="pill" href="#Gpills-four-example1" role="tab" aria-controls="Gpills-four-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Audio
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-five-example1-tab" data-toggle="pill" href="#Gpills-five-example1" role="tab" aria-controls="Gpills-five-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Smartphones
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-six-example1-tab" data-toggle="pill" href="#Gpills-six-example1" role="tab" aria-controls="Gpills-six-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            GPS & Navi
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-seven-example1-tab" data-toggle="pill" href="#Gpills-seven-example1" role="tab" aria-controls="Gpills-seven-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Computers
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-eight-example1-tab" data-toggle="pill" href="#Gpills-eight-example1" role="tab" aria-controls="Gpills-eight-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Portable Audio
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item flex-shrink-0 flex-lg-shrink-1">
                                    <a className="nav-link " id="Gpills-nine-example1-tab" data-toggle="pill" href="#Gpills-nine-example1" role="tab" aria-controls="Gpills-nine-example1" aria-selected="false">
                                        <div className="d-md-flex justify-content-md-center align-items-md-center">
                                            Accessories
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* End Nav Classic */}

                        {/* Tab Content */}
                        <div className="tab-content" id="Gpills-tabContent">
                            <div className="tab-pane fade pt-2 show active" id="Gpills-one-example1" role="tabpanel" aria-labelledby="Gpills-one-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-two-example1" role="tabpanel" aria-labelledby="Gpills-two-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-three-example1" role="tabpanel" aria-labelledby="Gpills-three-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-four-example1" role="tabpanel" aria-labelledby="Gpills-four-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-five-example1" role="tabpanel" aria-labelledby="Gpills-five-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-six-example1" role="tabpanel" aria-labelledby="Gpills-six-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-seven-example1" role="tabpanel" aria-labelledby="Gpills-seven-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-eight-example1" role="tabpanel" aria-labelledby="Gpills-eight-example1-tab">
                                <DealsSkeleton />
                            </div>
                            <div className="tab-pane fade pt-2" id="Gpills-nine-example1" role="tabpanel" aria-labelledby="Gpills-nine-example1-tab">
                                <DealsSkeleton />
                            </div>
                        </div>
                        {/* End Tab Content */}
                    </div>
                    {/* End Features Section */}
                </div>
            </>
        )
    }
}

export default Deals