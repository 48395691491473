import React from 'react';
import Application from './components/app/index';
import { Routes, Route } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
// import User from './components/User';
// import Articles from './components/Articles';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Application  />} />
      </Routes>
    </>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header>
//         <h1>React Skeletons</h1>
//       </header>

//       <div className="content">
//         <Articles />
//         <User />
//       </div>
//     </div>
//   );
// }

export default App;