import React, { Component } from "react";
import { MiniCartSkeleton } from './skeleton/skeletonElements';

class MiniCart extends Component {
    render () {
        return (
            <>
                <MiniCartSkeleton  />
            </>
        )
    }
}

export default MiniCart