import Header from './layout/Header';
import BestSellers from './layout/BestSellers';
import SpecialOffer from './layout/SpecialOffer';
import RecentlyViewed from './layout/RecentlyViewed';
import AccountSection from './layout/AccountSection'
import Products from './layout/Products';
import Deals from './layout/Deals';
import Slider from './layout/Slider';
import React, { Component }  from 'react';
import Footer from './layout/footer';

export default class Welcome extends Component {
    render () {
        return (
            <>
                <Header />
                {/* ========== MAIN CONTENT ========== */}
                <main id="content" role="main">
                    {/* Slider Section */}
                    <Slider  />
                    {/* End Slider Section */}
                    <div className="container">
                        {/* Banner */}
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-md-6 mb-4 mb-xl-0 col-xl-3">
                                    <a href="../shop/shop.html" className="d-black text-gray-90">
                                        <div className="min-height-132 py-1 d-flex bg-gray-1 align-items-center">
                                            <div className="col-6 col-xl-5 col-wd-6 pr-0">
                                                <img alt="" className="img-fluid" src="../../assets/img/190X150/img1.png"  />
                                            </div>
                                            <div className="col-6 col-xl-7 col-wd-6">
                                                <div className="mb-2 pb-1 font-size-18 font-weight-light text-ls-n1 text-lh-23">
                                                    CATCH BIG <strong>DEALS</strong> ON THE CAMERAS
                                                </div>
                                                <div className="link text-gray-90 font-weight-bold font-size-15" href="/#">
                                                    Shop now
                                                    <span className="link__icon ml-1">
                                                        <span className="link__icon-inner"><i className="ec ec-arrow-right-categproes"></i></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 mb-4 mb-xl-0 col-xl-3">
                                    <a href="../shop/shop.html" className="d-black text-gray-90">
                                        <div className="min-height-132 py-1 d-flex bg-gray-1 align-items-center">
                                            <div className="col-6 col-xl-5 col-wd-6 pr-0">
                                                <img alt="" className="img-fluid" src="../../assets/img/190X150/img2.jpg"  />
                                            </div>
                                            <div className="col-6 col-xl-7 col-wd-6">
                                                <div className="mb-2 pb-1 font-size-18 font-weight-light text-ls-n1 text-lh-23">
                                                    CATCH BIG <strong>DEALS</strong> ON THE CAMERAS
                                                </div>
                                                <div className="link text-gray-90 font-weight-bold font-size-15" href="/#">
                                                    Shop now
                                                    <span className="link__icon ml-1">
                                                        <span className="link__icon-inner"><i className="ec ec-arrow-right-categproes"></i></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 mb-4 mb-xl-0 col-xl-3">
                                    <a href="../shop/shop.html" className="d-black text-gray-90">
                                        <div className="min-height-132 py-1 d-flex bg-gray-1 align-items-center">
                                            <div className="col-6 col-xl-5 col-wd-6 pr-0">
                                                <img alt="" className="img-fluid" src="../../assets/img/190X150/img3.jpg"  />
                                            </div>
                                            <div className="col-6 col-xl-7 col-wd-6">
                                                <div className="mb-2 pb-1 font-size-18 font-weight-light text-ls-n1 text-lh-23">
                                                    CATCH BIG <strong>DEALS</strong> ON THE CAMERAS
                                                </div>
                                                <div className="link text-gray-90 font-weight-bold font-size-15" href="/#">
                                                    Shop now
                                                    <span className="link__icon ml-1">
                                                        <span className="link__icon-inner"><i className="ec ec-arrow-right-categproes"></i></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 mb-4 mb-xl-0 col-xl-3">
                                    <a href="../shop/shop.html" className="d-black text-gray-90">
                                        <div className="min-height-132 py-1 d-flex bg-gray-1 align-items-center">
                                            <div className="col-6 col-xl-5 col-wd-6 pr-0">
                                                <img alt="" className="img-fluid" src="../../assets/img/190X150/img4.png"  />
                                            </div>
                                            <div className="col-6 col-xl-7 col-wd-6">
                                                <div className="mb-2 pb-1 font-size-18 font-weight-light text-ls-n1 text-lh-23">
                                                    CATCH BIG <strong>DEALS</strong> ON THE CAMERAS
                                                </div>
                                                <div className="link text-gray-90 font-weight-bold font-size-15" href="/#">
                                                    Shop now
                                                    <span className="link__icon ml-1">
                                                        <span className="link__icon-inner"><i className="ec ec-arrow-right-categproes"></i></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* End Banner */}
                        {/* Deals-and-tabs */}
                        <div className="mb-5">
                            <div className="row">
                                {/* Deal */}
                                <SpecialOffer  />
                                {/* End Deal */}
                                {/* Tab Prodcut */}
                                <Products  />
                                {/* End Tab Prodcut */}
                            </div>
                        </div>
                        {/* End Deals-and-tabs */}
                    </div>
                    {/* Products-4-1-4 */}
                    <Deals  />
                    {/* End Products-4-1-4 */}
                    <div className="container">
                        {/* Prodcut-cards-carousel */}
                        <BestSellers />
                        {/* End Prodcut-cards-carousel */}
                        {/* Full banner */}
                        <div className="mb-6">
                            <a href="../shop/shop.html" className="d-block text-gray-90">
                                <div className="" style={{ backgroundImage: 'url(../../assets/img/1400X206/img1.jpg)' }}>
                                    <div className="space-top-2-md p-4 pt-6 pt-md-8 pt-lg-6 pt-xl-8 pb-lg-4 px-xl-8 px-lg-6">
                                        <div className="flex-horizontal-center mt-lg-3 mt-xl-0 overflow-auto overflow-md-visble">
                                            <h1 className="text-lh-38 font-size-32 font-weight-light mb-0 flex-shrink-0 flex-md-shrink-1">SHOP AND <strong>SAVE BIG</strong> ON HOTTEST TABLETS</h1>
                                            <div className="ml-5 flex-content-center flex-shrink-0">
                                                <div className="bg-primary rounded-lg px-6 py-2">
                                                    <em className="font-size-14 font-weight-light">STARTING AT</em>
                                                    <div className="font-size-30 font-weight-bold text-lh-1">
                                                        <sup className="">$</sup>79<sup className="">99</sup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {/* End Full banner */}
                        {/* Recently viewed */}
                        <RecentlyViewed />
                        {/* End Recently viewed */}
                        {/* Brand Carousel */}
                        <div className="mb-8">
                            <div className="py-2 border-top border-bottom">
                                <div className="js-slick-carousel u-slick my-1"
                                    data-slides-show="5"
                                    data-slides-scroll="1"
                                    data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-normal u-slick__arrow-centered--y"
                                    data-arrow-left-classes="fa fa-angle-left u-slick__arrow-classic-inner--left z-index-9"
                                    data-arrow-right-classes="fa fa-angle-right u-slick__arrow-classic-inner--right"
                                    data-responsive='[{
                                    "breakpoint": 992,
                                    "settings": {
                                        "slidesToShow": 2
                                    }
                                }, {
                                    "breakpoint": 768,
                                    "settings": {
                                        "slidesToShow": 1
                                    }
                                }, {
                                    "breakpoint": 554,
                                    "settings": {
                                        "slidesToShow": 1
                                    }
                                }]'>
                                    <div className="js-slide">
                                        <a href="/#" className="link-hover__brand">
                                            <img alt="" className="img-fluid m-auto max-height-50" src="../../assets/img/200X60/img1.png"  />
                                        </a>
                                    </div>
                                    <div className="js-slide">
                                        <a href="/#" className="link-hover__brand">
                                            <img alt="" className="img-fluid m-auto max-height-50" src="../../assets/img/200X60/img2.png"  />
                                        </a>
                                    </div>
                                    <div className="js-slide">
                                        <a href="/#" className="link-hover__brand">
                                            <img alt="" className="img-fluid m-auto max-height-50" src="../../assets/img/200X60/img3.png"  />
                                        </a>
                                    </div>
                                    <div className="js-slide">
                                        <a href="/#" className="link-hover__brand">
                                            <img alt="" className="img-fluid m-auto max-height-50" src="../../assets/img/200X60/img4.png"  />
                                        </a>
                                    </div>
                                    <div className="js-slide">
                                        <a href="/#" className="link-hover__brand">
                                            <img alt="" className="img-fluid m-auto max-height-50" src="../../assets/img/200X60/img5.png"  />
                                        </a>
                                    </div>
                                    <div className="js-slide">
                                        <a href="/#" className="link-hover__brand">
                                            <img alt="" className="img-fluid m-auto max-height-50" src="../../assets/img/200X60/img6.png"  />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Brand Carousel */}
                    </div>
                </main>
                {/* ========== END MAIN CONTENT ========== */}
                <Footer  />
                {/* ========== Account Section */}
                <AccountSection  />
            </>
        )
    }
}