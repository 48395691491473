import React, { Component }  from 'react';

export default function SubMenu_2() {
    return (
        <>
            <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                {/* Navigation */}
                <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse">
                    <ul className="navbar-nav u-header__navbar-nav">
                        {/* Home */}
                        <li className="nav-item hs-has-mega-menu u-header__nav-item"
                            data-event="click"
                            data-animation-in="slideInUp"
                            data-animation-out="fadeOut"
                            data-position="left">
                            <a id="homeMegaMenu" className="nav-link u-header__nav-link u-header__nav-link-toggle text-sale" href="/#"aria-expanded="false">Super Deals</a>

                            {/* Home - Mega Menu */}
                            <div className="hs-mega-menu w-100 u-header__sub-menu" aria-labelledby="homeMegaMenu">
                                <div className="row u-header__mega-menu-wrapper">
                                    <div className="col-md-3">
                                        <span className="u-header__sub-menu-title">Home &amp; Static Pages</span>
                                        <ul className="u-header__sub-menu-nav-group">
                                            <li><a href="index.html" className="nav-link u-header__sub-menu-nav-link">Home v1</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="u-header__sub-menu-title">Shop Pages</span>
                                        <ul className="u-header__sub-menu-nav-group mb-3">
                                            <li><a href="../shop/shop-grid.html" className="nav-link u-header__sub-menu-nav-link">Shop Grid</a></li>
                                        </ul>
                                        <span className="u-header__sub-menu-title">Product Categories</span>
                                        <ul className="u-header__sub-menu-nav-group">
                                            <li><a href="../shop/product-categories-4-column-sidebar.html" className="nav-link u-header__sub-menu-nav-link">4 Column Sidebar</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="u-header__sub-menu-title">Single Product Pages</span>
                                        <ul className="u-header__sub-menu-nav-group mb-3">
                                            <li><a href="../shop/single-product-extended.html" className="nav-link u-header__sub-menu-nav-link">Single Product Extended</a></li>
                                        </ul>
                                        <span className="u-header__sub-menu-title">Ecommerce Pages</span>
                                        <ul className="u-header__sub-menu-nav-group">
                                            <li><a href="../shop/shop.html" className="nav-link u-header__sub-menu-nav-link">Shop</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="u-header__sub-menu-title">Blog Pages</span>
                                        <ul className="u-header__sub-menu-nav-group mb-3">
                                            <li><a href="../blog/blog-v1.html" className="nav-link u-header__sub-menu-nav-link">Blog v1</a></li>
                                        </ul>
                                        <span className="u-header__sub-menu-title">Shop Columns</span>
                                        <ul className="u-header__sub-menu-nav-group">
                                            <li><a href="../shop/shop-7-columns-full-width.html" className="nav-link u-header__sub-menu-nav-link">7 Column Full width</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Home - Mega Menu */}
                        </li>
                        {/* End Home */}

                        {/* Featured Brands */}
                        <li className="nav-item u-header__nav-item">
                            <a className="nav-link u-header__nav-link" href="/#"aria-expanded="false" aria-labelledby="pagesSubMenu">Featured Brands</a>
                        </li>
                        {/* End Featured Brands */}

                        {/* Trending Styles */}
                        <li className="nav-item u-header__nav-item">
                            <a className="nav-link u-header__nav-link" href="/#"aria-expanded="false" aria-labelledby="blogSubMenu">Trending Styles</a>
                        </li>
                        {/* End Trending Styles */}

                        {/* Gift Cards */}
                        <li className="nav-item u-header__nav-item">
                            <a className="nav-link u-header__nav-link" href="/#"aria-expanded="false">Gift Cards</a>
                        </li>
                        {/* End Gift Cards */}

                        {/* Button */}
                        <li className="nav-item u-header__nav-last-item">
                            <a className="text-gray-90" href="/#" target="_blank">
                                Free Shipping on Orders $50+
                            </a>
                        </li>
                        {/* End Button */}
                    </ul>
                </div>
                {/* End Navigation */}
            </nav>
        </>
    )
}