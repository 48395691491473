import React, { Component }  from 'react';

export default function SubMenu_1() {
    return (
        <>
            <div className="col-md-auto d-none d-xl-block">
                <div className="max-width-270 min-width-270">
                    {/* Basics Accordion */}
                    <div id="basicsAccordion">
                        {/* Card */}
                        <div className="card border-0">
                            <div className="card-header card-collapse border-0" id="basicsHeadingOne">
                                <button type="button" className="btn-link btn-remove-focus btn-block d-flex card-btn py-3 text-lh-1 px-4 shadow-none btn-primary rounded-top-lg border-0 font-weight-bold text-gray-90"
                                    data-toggle="collapse"
                                    data-target="#basicsCollapseOne"
                                    aria-expanded="true"
                                    aria-controls="basicsCollapseOne">
                                    <span className="ml-0 text-gray-90 mr-2">
                                        <span className="fa fa-list-ul"></span>
                                    </span>
                                    <span className="pl-1 text-gray-90">All Departments</span>
                                </button>
                            </div>
                            <div id="basicsCollapseOne" className="collapse show vertical-menu"
                                aria-labelledby="basicsHeadingOne"
                                data-parent="#basicsAccordion">
                                <div className="card-body p-0">
                                    <nav className="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space hs-menu-initialized">
                                        <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse">
                                            <ul className="navbar-nav u-header__navbar-nav">
                                                <li className="nav-item u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a href="/#" className="nav-link u-header__nav-link font-weight-bold">Value of the Day</a>
                                                </li>
                                                <li className="nav-item u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a href="/#" className="nav-link u-header__nav-link font-weight-bold">Top 100 Offers</a>
                                                </li>
                                                <li className="nav-item u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a href="/#" className="nav-link u-header__nav-link font-weight-bold">New Arrivals</a>
                                                </li>
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-animation-in="slideInUp"
                                                    data-animation-out="fadeOut"
                                                    data-position="left">
                                                    <a id="basicMegaMenu" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">Computers & Accessories</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu" aria-labelledby="basicMegaMenu">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img1.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Computers &amp; Accessories</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Computers &amp; Accessories</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Laptops, Desktops &amp; Monitors</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Printers &amp; Ink</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Networking &amp; Internet Devices</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Computer Accessories</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Software</a></li>
                                                                    <li>
                                                                        <a className="nav-link u-header__sub-menu-nav-link u-nav-divider border-top pt-2 flex-column align-items-start" href="/#">
                                                                            <div className="">All Electronics</div>
                                                                            <div className="u-nav-subtext font-size-11 text-gray-30">Discover more products</div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Office &amp; Stationery</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Office &amp; Stationery</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a id="basicMegaMenu1" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">Cameras, Audio & Video</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu" aria-labelledby="basicMegaMenu1">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img4.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Cameras &amp; Photography</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Lenses</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Camera Accessories</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Security &amp; Surveillance</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Binoculars &amp; Telescopes</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Camcorders</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Software</a></li>
                                                                    <li>
                                                                        <a className="nav-link u-header__sub-menu-nav-link u-nav-divider border-top pt-2 flex-column align-items-start" href="/#">
                                                                            <div className="">All Electronics</div>
                                                                            <div className="u-nav-subtext font-size-11 text-gray-30">Discover more products</div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Audio &amp; Video</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Audio &amp; Video</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Headphones &amp; Speakers</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a id="basicMegaMenu2" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">Mobiles & Tablets</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu vmm-bg-extended" aria-labelledby="basicMegaMenu2">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img3.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Mobiles &amp; Tablets</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Mobile Phones</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Smartphones</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Refurbished Mobiles</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link border-top pt-2" href="/#">All Mobile Accessories</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Cases &amp; Covers</a></li>
                                                                    <li>
                                                                        <a className="nav-link u-header__sub-menu-nav-link u-nav-divider border-top pt-2 flex-column align-items-start" href="/#">
                                                                            <div className="">All Electronics</div>
                                                                            <div className="u-nav-subtext font-size-11 text-gray-30">Discover more products</div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Tablets</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Tablet Accessories</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a id="basicMegaMenu3" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">Movies, Music & Video Game</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu" aria-labelledby="basicMegaMenu3">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img2.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Movies &amp; TV Shows</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Movies &amp; TV Shows</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All English</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link border-bottom pb-3" href="/#">All Hindi</a></li>
                                                                </ul>
                                                                <span className="u-header__sub-menu-title">Video Games</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">PC Games</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Consoles</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Accessories</a></li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Music</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Music</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Indian Classical</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Musical Instruments</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a id="basicMegaMenu4" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">TV & Audio</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu" aria-labelledby="basicMegaMenu4">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img5.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Audio &amp; Video</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Audio &amp; Video</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Televisions</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Headphones</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Speakers</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Audio &amp; Video Accessories</a></li>
                                                                    <li>
                                                                        <a className="nav-link u-header__sub-menu-nav-link u-nav-divider border-top pt-2 flex-column align-items-start" href="/#">
                                                                            <div className="">Electro Home Appliances</div>
                                                                            <div className="u-nav-subtext font-size-11 text-gray-30">Available in select cities</div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Music</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Televisions</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Headphones</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a id="basicMegaMenu5" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">Watches & Eyewear</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu" aria-labelledby="basicMegaMenu5">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img6.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Watches</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Watches</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Men's Watches</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Women's Watches</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Premium Watches</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Deals on Watches</a></li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Eyewear</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Men's Sunglasses</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item MegaMenu */}
                                                <li className="nav-item hs-has-mega-menu u-header__nav-item"
                                                    data-event="hover"
                                                    data-position="left">
                                                    <a id="basicMegaMenu3" className="nav-link u-header__nav-link u-header__nav-link-toggle" href="/#"  aria-expanded="false">Car, Motorbike & Industrial</a>

                                                    {/* Nav Item - Mega Menu */}
                                                    <div className="hs-mega-menu vmm-tfw u-header__sub-menu" aria-labelledby="basicMegaMenu3">
                                                        <div className="vmm-bg">
                                                            <img className="img-fluid" src="../../assets/img/500X400/img7.png" alt="" />
                                                        </div>
                                                        <div className="row u-header__mega-menu-wrapper">
                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Car &amp; Motorbike</span>
                                                                <ul className="u-header__sub-menu-nav-group mb-3">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Cars &amp; Bikes</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Car &amp; Bike Care</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link border-bottom pb-3" href="/#">Lubricants</a></li>
                                                                </ul>
                                                                <span className="u-header__sub-menu-title">Shop for Bike</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Helmets &amp; Gloves</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Bike Parts</a></li>
                                                                </ul>
                                                            </div>

                                                            <div className="col mb-3 mb-sm-0">
                                                                <span className="u-header__sub-menu-title">Industrial Supplies</span>
                                                                <ul className="u-header__sub-menu-nav-group">
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">All Industrial Supplies</a></li>
                                                                    <li><a className="nav-link u-header__sub-menu-nav-link" href="/#">Lab &amp; Scientific</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Nav Item - Mega Menu */}
                                                </li>
                                                {/* End Nav Item MegaMenu */}
                                                {/* Nav Item */}
                                                <li className="nav-item hs-has-sub-menu u-header__nav-item"
                                                    data-event="click"
                                                    data-animation-in="slideInUp"
                                                    data-animation-out="fadeOut"
                                                    data-position="left">
                                                    <a id="homeMegaMenu" className="nav-link u-header__nav-link u-header__nav-link-toggle u-header__nav-link-toggle" href="/#"  aria-expanded="false" aria-labelledby="homeSubMenu">Vendors</a>

                                                    {/* Home - Submenu */}
                                                    <ul id="homeSubMenu" className="hs-sub-menu u-header__sub-menu animated hs-position-left fadeOut" aria-labelledby="homeMegaMenu" style={{ minWidth: 230+'px', display: 'none' }}>
                                                        {/* Home-v1 */}
                                                        <li className="hs-has-sub-menu">
                                                            <a className="nav-link u-header__sub-menu-nav-link " href="index.html">Home-v1</a>
                                                        </li>
                                                        {/* End Home-v1 */}
                                                    </ul>
                                                    {/* End Home - Submenu */}
                                                </li>
                                                {/* End Nav Item */}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/* End Card */}
                    </div>
                    {/* End Basics Accordion */}
                </div>
            </div>
        </>
    )
}