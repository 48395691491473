import React, { Component } from 'react';
import { ProductSkeleton } from './skeleton/skeletonElements';

class Products extends Component {
    render() {
        return (
            <>
                <div className="col">
                    {/* Features Section */}
                    <div className="">
                        {/* Nav Classic */}
                        {this.NavClassic()}
                        {/* End Nav Classic */}

                        {/* Tab Content */}
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade pt-2 show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
                                {/* products goes here */}
                                <ul className="row list-unstyled products-group no-gutters">
                                    <ProductSkeleton />
                                </ul>
                            </div>
                            <div className="tab-pane fade pt-2" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab">
                                <ul className="row list-unstyled products-group no-gutters">
                                    <ProductSkeleton />
                                </ul>
                            </div>
                            <div className="tab-pane fade pt-2" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab">
                                <ul className="row list-unstyled products-group no-gutters">
                                    <ProductSkeleton />
                                    {/* <li className="col-6 col-wd-3 col-md-4 product-item">
                                        <div className="product-item__outer h-100">
                                            <div className="product-item__inner px-xl-4 p-3">
                                                <div className="product-item__body pb-xl-2">
                                                    <div className="mb-2"><a href="../shop/product-categories-7-column-full-width.html" className="font-size-12 text-gray-5">Speakers</a></div>
                                                    <h5 className="mb-1 product-item__title"><a href="../shop/single-product-fullwidth.html" className="text-blue font-weight-bold">Wireless Audio System Multiroom 360 degree Full base audio</a></h5>
                                                    <div className="mb-2">
                                                        <a href="../shop/single-product-fullwidth.html" className="d-block text-center"><img alt="" className="img-fluid" src="../../assets/img/212X200/img1.jpg" /></a>
                                                    </div>
                                                    <div className="flex-center-between mb-1">
                                                        <div className="prodcut-price">
                                                            <div className="text-gray-100">$685,00</div>
                                                        </div>
                                                        <div className="d-none d-xl-block prodcut-add-cart">
                                                            <a href="../shop/single-product-fullwidth.html" className="btn-add-cart btn-primary transition-3d-hover"><i className="ec ec-add-to-cart"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-item__footer">
                                                    <div className="border-top pt-2 flex-center-between flex-wrap">
                                                        <a href="../shop/compare.html" className="text-gray-6 font-size-13"><i className="ec ec-compare mr-1 font-size-15"></i> Compare</a>
                                                        <a href="../shop/wishlist.html" className="text-gray-6 font-size-13"><i className="ec ec-favorites mr-1 font-size-15"></i> Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                        {/* End Tab Content */}
                    </div>
                    {/* End Features Section */}
                </div>
            </>
        )
    }

    NavClassic = () => {
        return <>
            <div className="position-relative bg-white text-center z-index-2">
                <ul className="nav nav-classic nav-tab justify-content-center" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active " id="pills-one-tab" data-toggle="pill" href="#pills-one" role="tab" aria-controls="pills-one" aria-selected="true">
                            <div className="d-md-flex justify-content-md-center align-items-md-center">
                                Featured
                            </div>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="pills-two-tab" data-toggle="pill" href="#pills-two" role="tab" aria-controls="pills-two" aria-selected="false">
                            <div className="d-md-flex justify-content-md-center align-items-md-center">
                                On Sale
                            </div>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="pills-three-tab" data-toggle="pill" href="#pills-three" role="tab" aria-controls="pills-three" aria-selected="false">
                            <div className="d-md-flex justify-content-md-center align-items-md-center">
                                Top Rated
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    }
}

export default Products;