import React, { Component }  from 'react';

export default class Navigation extends Component {
    render() {
        return (
            <>
                <h1>This is navbar component</h1>
            </>
        )
    }
}