import "./skeleton.css";
import React from "react";

export function ProductSkeleton() {
    return (
        <>
            {[1, 2, 3, 4, 5, 6].map((i) => (
                <li key={i} className="col-6 col-wd-3 col-md-4 d-wd-block remove-divider-wd">
                    <div className="product-item__outer h-100">
                        <div className="product-item__inner px-xl-4 p-3">
                            <div className="product-item__body pb-xl-2">
                                <div className="mb-2 skeleton text_two"></div>
                                <h5 className="mb-1 product-item__title skeleton title"></h5>
                                <div className="mb-2">
                                    <a href="/#" className="d-block text-center img-fluid skeleton thumbnail"
                                    ></a>
                                </div>
                                <div className="flex-center-between mb-1">
                                    <div className="text-gray-100 skeleton text"></div>
                                    <div className="btn-add-carts d-xl-block prodcut-add-cart"></div>
                                </div>
                            </div>
                            <div className="product-item__footer">
                                <div className="border-top pt-2 flex-center-between flex-wrap">
                                    <div className="text-gray-6 font-size-13 skeleton text"></div>
                                    <div className="text-gray-6 font-size-13 skeleton text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </>
    );
}

export function BestSellersSkeleton() {
    return (
        <>
            {[1, 2, 3, 4, 5, 6].map((i) => (
                <li key={i} className="col-wd-3 col-md-4 pb-2 mb-2 pb-md-0 mb-md-0 border-bottom border-md-bottom-0">
                    <div className="product-item__outer h-100">
                        <div className="product-item__inner p-md-3 row no-gutters">
                            <div className="col col-lg-auto product-media-left">
                                <a href="/#" className="max-width-150 d-block img-fluid skeleton thumbnail2"></a>
                            </div>
                            <div className="col product-item__body pl-2 pl-lg-3 mr-xl-2 mr-wd-1">
                                <div className="mb-4">
                                    <div className="mb-2 skeleton" style={{ width: '100%', height: '12px' }}>
                                        <a href="/#" className="font-size-12 text-gray-5 skeleton" style={{ width: '100%', height: '12px' }}></a>
                                    </div>
                                    <h5 className="product-item__title skeleton title">
                                        <a href="/#" className="text-blue font-weight-bold skeleton title"></a>
                                    </h5>
                                </div>
                                <div className="flex-center-between mb-3">
                                    <div className="prodcut-price skeleton text">
                                        <div className="text-gray-100"></div>
                                    </div>
                                    <div className="d-none d-xl-block prodcut-add-cart">
                                        <a href="/#" className="btn-add-carts"></a>
                                    </div>
                                </div>
                                <div className="product-item__footer">
                                    <div className="border-top pt-2 flex-center-between flex-wrap">
                                        <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </>
    );
}

export function RecentyViewedSkeleton() {
    return (
        <>
            {
                [1, 2, 3, 4, 5, 6].map((i) => (
                    <div key={i} className="js-slide products-group">
                        <div className="">
                            <div className="product-item__outer h-100">
                                <div className="product-item__inner px-wd-4 p-2 p-md-3">
                                    <div className="product-item__body pb-xl-2">
                                        <div className="mb-2 skeleton" style={{ width: '100%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center img-fluid skeleton thumbnail"></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price">
                                                <div className="text-gray-100 skeleton text"></div>
                                            </div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export function SpecialOfferSkeleton() {
    return (
        <>
            <div className="col-md-auto mb-6 mb-md-0">
                <div className="p-3 border border-width-2 border-primary borders-radius-20 bg-white min-width-370">
                    <div className="d-flex justify-content-between align-items-center m-1 ml-2">
                        <h3 className="font-size-22 mb-0 font-weight-normal text-lh-28 max-width-120 skeleton text"></h3>
                        <div className="d-flex align-items-center flex-column justify-content-center skeleton avatar2 rounded-pill height-75 width-75 text-lh-1"></div>
                    </div>
                    <div className="mb-4">
                        <a href="/#" className="d-block text-center skeleton thumbnail3"></a>
                    </div>
                    <h5 className="mb-2 font-size-14 text-center mx-auto max-width-180 text-lh-18 skeleton title"></h5>
                    <div className="d-flex align-items-center justify-content-center mb-3 skeleton text"></div>
                    <div className="mb-3 mx-2">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span className="skeleton" style={{ width: '40%', height: '12px' }}></span>
                            <span className="skeleton" style={{ width: '40%', height: '12px' }}></span>
                        </div>
                        <div className="rounded-pill bg-gray-3 height-20 position-relative">
                            {/* <span className="position-absolute left-0 top-0 bottom-0 rounded-pill w-30 bg-primary"></span> */}
                        </div>
                    </div>
                    <div className="mb-2">
                        <h6 className="font-size-15 text-gray-2 text-center mb-3">Hurry Up! Offer ends in:</h6>
                        <div className="js-countdown d-flex justify-content-center"
                            data-end-date="2020/11/30"
                            data-hours-format="%H"
                            data-minutes-format="%M"
                            data-seconds-format="%S">
                            <div className="text-lh-1">
                                <div className="text-gray-2 font-size-30 bg-gray-4 py-2 px-2 rounded-sm mb-2">
                                    <span className="js-cd-hours"></span>
                                </div>
                                <div className="text-gray-2 font-size-12 text-center">HOURS</div>
                            </div>
                            <div className="mx-1 pt-1 text-gray-2 font-size-24">:</div>
                            <div className="text-lh-1">
                                <div className="text-gray-2 font-size-30 bg-gray-4 py-2 px-2 rounded-sm mb-2">
                                    <span className="js-cd-minutes"></span>
                                </div>
                                <div className="text-gray-2 font-size-12 text-center">MINS</div>
                            </div>
                            <div className="mx-1 pt-1 text-gray-2 font-size-24">:</div>
                            <div className="text-lh-1">
                                <div className="text-gray-2 font-size-30 bg-gray-4 py-2 px-2 rounded-sm mb-2">
                                    <span className="js-cd-seconds"></span>
                                </div>
                                <div className="text-gray-2 font-size-12 text-center">SECS</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function DealsSkeleton() {
    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-3 col-wd-4 d-md-flex d-wd-block">
                    <ul className="row list-unstyled products-group no-gutters mb-0 flex-xl-column flex-wd-row">
                        <li className="col-xl-6 max-width-xl-100 remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                        <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price">
                                                <div className="text-gray-100 skeleton text"></div>
                                            </div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-xl-6 max-width-xl-100 remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                        <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-xl-6 max-width-xl-100 d-md-none d-wd-block remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                    <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-xl-6 max-width-xl-100 d-md-none d-wd-block remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                    <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton img-fluid" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 col-wd-4 products-group-1">
                    <ul className="row list-unstyled products-group no-gutters bg-white h-100 mb-0">
                        <li className="col remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body d-flex flex-column">
                                        <div className="mb-1">
                                        <div className="mb-2 skeleton" style={{ width: '50%', height: '12px' }}></div>
                                            <h5 className="mb-0 product-item__title skeleton title"></h5>
                                        </div>
                                        <div className="mb-1 min-height-4-1-4">
                                            <a href="/#" className="d-block text-center my-4 mt-lg-6 mb-lg-5 mt-xl-0 mb-xl-0 mt-wd-6 mb-wd-5 skeleton img-fluid" style={{ width: '564px', height: '520px' }}></a>
                                            {/* Gallery */}
                                            <div className="row mx-gutters-2 mb-3">
                                                <div className="col-auto">
                                                    {/* Gallery */}
                                                    <a className="js-fancybox max-width-60 u-media-viewer skeleton" style={{ width: '100px', height: '60px' }} href="/#"></a>
                                                    {/* End Gallery */}
                                                </div>

                                                <div className="col-auto">
                                                    {/* Gallery */}
                                                    <a className="js-fancybox max-width-60 u-media-viewer skeleton" style={{ width: '100px', height: '60px' }} href="/#"></a>
                                                    {/* End Gallery */}
                                                </div>

                                                <div className="col-auto">
                                                    {/* Gallery */}
                                                    <a className="js-fancybox max-width-60 u-media-viewer skeleton" style={{ width: '100px', height: '60px' }} href="/#"></a>
                                                    {/* End Gallery */}
                                                </div>
                                                <div className="col"></div>
                                            </div>
                                            {/* End Gallery */}
                                        </div>
                                        <div className="flex-center-between">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts skeleton btn-add-cart__wide2"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3 col-wd-4 d-md-flex d-wd-block">
                    <ul className="row list-unstyled products-group no-gutters mb-0 flex-xl-column flex-wd-row">
                        <li className="col-xl-6 max-width-xl-100 remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                    <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton img-fluid" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-xl-6 max-width-xl-100 remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                    <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center img-fluid skeleton" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-xl-6 max-width-xl-100 d-md-none d-wd-block remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                    <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-xl-6 max-width-xl-100 d-md-none d-wd-block remove-divider">
                            <div className="product-item__outer h-100 w-100 prodcut-box-shadow">
                                <div className="product-item__inner bg-white p-3">
                                    <div className="product-item__body pb-xl-2">
                                    <div className="mb-2 skeleton" style={{ width: '80%', height: '12px' }}></div>
                                        <h5 className="mb-1 product-item__title skeleton title"></h5>
                                        <div className="mb-2">
                                            <a href="/#" className="d-block text-center skeleton" style={{ width: '212px', height: '200px' }}></a>
                                        </div>
                                        <div className="flex-center-between mb-1">
                                            <div className="prodcut-price skeleton text"></div>
                                            <div className="d-none d-xl-block prodcut-add-cart">
                                                <a href="/#" className="btn-add-carts"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-item__footer">
                                        <div className="border-top pt-2 flex-center-between flex-wrap">
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                            <a href="/#" className="text-gray-6 font-size-13 skeleton text"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export function MiniCartSkeleton() {
    return (
        <>
            <ul className="list-unstyled px-3 pt-3">
                <li className="border-bottom pb-3 mb-3">
                    <div className="">
                        <ul className="list-unstyled row mx-n2">
                            <div className="px-2 col-auto skeleton img-fluid" style={{ width: '100px', hight: '100px' }}></div>
                            <li className="px-2 col">
                                <h5 className="text-blue font-size-14 font-weight-bold skeleton title"></h5>
                                <span className="font-size-14 skeleton text"></span>
                            </li>
                            <li className="px-2 col-auto skeleton" style={{ width: '20px', height: '20px' }}></li>
                        </ul>
                    </div>
                </li>
            </ul>
            <div className="flex-center-between px-4 pt-2">
                <a href="/#" className="btn skeleton mb-3 mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5"></a>
                <a href="/#" className="btn skeleton ml-md-2 px-5 px-md-4 px-lg-5"></a>
            </div>
        </>
    )
}

export function FooterMenu() {
    return (
        <>
            {
                [1, 2, 3].map((i) => (
                    <li key={i} className="product-item product-item__list row no-gutters mb-6 remove-divider">
                        <div className="col-auto skeleton" style={{ width: '75px', height: '75px' }}></div>
                        <div className="col pl-4 d-flex flex-column">
                            <h5 className="product-item__title mb-0 skeleton title"></h5>
                            <div className="prodcut-price mt-auto">
                                <div className="font-size-15 skeleton text"></div>
                            </div>
                        </div>
                    </li>
                ))
            }
        </>
    )
}
